<template>
    <div class="theory-content">
        <div class="top-content">
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="role == 0">
                <el-breadcrumb-item :to="{ path: '/admin/datacenter' }">数据中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/admin/datacenter' }">得分榜单</el-breadcrumb-item>
                <el-breadcrumb-item>学生考试详情</el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="role == 1">
                <el-breadcrumb-item :to="{ path: '/schooladmin/datacenter' }">数据中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/schooladmin/datacenter' }">得分榜单</el-breadcrumb-item>
                <el-breadcrumb-item>学生考试详情</el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb  separator-class="el-icon-arrow-right" v-if="role == 2">
                <el-breadcrumb-item :to="{ path: '/trainadmin/datacenter' }">数据中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/trainadmin/datacenter' }">得分榜单</el-breadcrumb-item>
                <el-breadcrumb-item>学生考试详情</el-breadcrumb-item>
            </el-breadcrumb>

            <div class="paper-table">
                <div class="paper-total">
                    <div class="total">
                        <span>{{answerData.user_name}}</span>
                    </div>
                </div>
                <div class="answer-case">
                    <span class="case-title">学生得分</span>
                    <div class="case-score score-item">
                        <span>{{answerData.student_paper_score}}</span>
                        <span class="score-unit">分</span>
                    </div>
                </div>
                <div class="topic-type" v-for="(item,index) in questionDetail" :key="index">
                    <div class="type-item">{{item.name | formatHeadLineType}}<strong
                            class="text-gray">(满分{{item.total_score?item.total_score:0}}分)</strong></div>
                    <div class="type-item score-item">{{item.total_student_score}}分</div>
                </div>
            </div>
            <div class="paper-name">试卷名称：{{answerData.paper_name}}</div>
        </div>
        <el-scrollbar class="exerciseDetail-main" :native="false">
            <AnswerDetail :paperDetail="paperDetail"></AnswerDetail>
        </el-scrollbar>
    </div>
</template>

<script>
    import AnswerDetail from "../AnswerDetail";
    import {getStudentExamDetail} from '@/utils/apis'

    export default {
        name: "TheoryExam",
        components: {
            AnswerDetail
        },
        data() {
            return {
                answerData: {},
                paperDetail: {},
                questionDetail: [],
                role:null
            }
        },
        filters: {
            // 题型
            formatHeadLineType(type) {
                const types = {
                    'single_data': '单选题',
                    'multiple_data': '多选题',
                    'judge_data': '判断题',
                    'blank_data': '填空题',
                    'answer_data': '问答题',
                }
                return types[type]
            },
            // 试题答案格式
            formatNumber(nums) {
                const numbers = {
                    '1': 'A',
                    '2': 'B',
                    '3': 'C',
                    '4': 'D',
                    '5': 'E',
                    '6': 'F',
                }
                return numbers[nums]
            },
        },
        created() {
            this.role = Number(localStorage.getItem('role'))
        },
        mounted() {
            this.getAnswerDetail()
        },
        methods: {
            getAnswerDetail() {
                let param = {
                    competition_id: this.$route.query.cId,
                    user_id: this.$route.query.id
                }
                getStudentExamDetail(param).then(res => {
                    if (res.code == 200) {
                        this.answerData = res.data
                        let obj = {}
                        obj.single_data = res.data.single_data
                        obj.multiple_data = res.data.multiple_data
                        obj.judge_data = res.data.judge_data
                        obj.blank_data = res.data.blank_data
                        obj.answer_data = res.data.answer_data
                        for (let i in obj) {
                            obj[i].name = i
                            obj[i].list.forEach(item => {
                                item.options = JSON.parse(item.options)
                                item.answer = JSON.parse(item.answer)
                                item.stu_answer = JSON.parse(item.stu_answer)
                                this.$set(item, 'isAnalysisShow', false)
                            })
                            this.questionDetail.push(obj[i])
                        }
                        this.paperDetail = JSON.parse(JSON.stringify(obj))
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .theory-content {
        padding: 30px;
        height: calc(100% - 60px);
        display: flex;
        flex-direction: column;

        .exerciseDetail-main {
            height: 1%;
            flex: 1;
            margin-top: 20px;

            ::v-deep > .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }

        ::v-deep .el-breadcrumb__item {

            &:last-child {

                .el-breadcrumb__inner {
                    color: #13131B;
                    font-weight: bold;
                }
            }

            .el-breadcrumb__inner {
                color: #999;
            }
        }

        .paper-table {
            display: flex;
            margin: 24px 0;
            height: 100px;

            .paper-total {
                display: flex;
                flex-direction: column;
                border: 1px solid #EAEAEA;
                height: 100%;

                .total {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 24px;
                    color: #333333;
                    width: 184px;
                }
            }

            .answer-case {
                display: flex;
                flex-direction: column;
                border: 1px solid #EAEAEA;
                border-left: none;
                height: 100%;
                width: 184px;
                justify-content: center;
                align-items: center;

                .case-title {
                    font-size: 16px;
                    color: #333333;
                }

                .case-score {
                    font-size: 30px;
                    margin-top: 10px;

                    .score-unit {
                        font-size: 16px;
                    }
                }
            }

            .score-item {
                color: #1122D8;
            }

            .topic-type {
                display: flex;
                flex-direction: column;
                border: 1px solid #EAEAEA;
                border-left: none;
                height: 100%;
                flex: 1;


                .type-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-bottom: 1px solid #EAEAEA;
                    box-sizing: border-box;
                    font-size: 18px;
                    font-weight: 400;
                    height: 50%;

                    .text-gray {
                        font-weight: 400;
                        color: #333333;
                        margin-left: 5px;
                    }
                }
            }
        }

        .paper-name {
            margin-top: 38px;
            text-align: center;
            font-size: 18px;
            color: #333333;
        }
    }
</style>