<template>
    <div class="optimizeKeyword">
        <div class="optimizeKeyword-header">
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="role == 0">
                <el-breadcrumb-item :to="{ path: '/admin/datacenter' }">数据中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/admin/datacenter' }">得分榜单</el-breadcrumb-item>
                <el-breadcrumb-item>关键词优化</el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="role == 1">
                <el-breadcrumb-item :to="{ path: '/schooladmin/datacenter' }">数据中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/schooladmin/datacenter' }">得分榜单</el-breadcrumb-item>
                <el-breadcrumb-item>关键词优化</el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="role === 2">
                <el-breadcrumb-item :to="{ path: '/trainadmin/datacenter' }">数据中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/trainadmin/datacenter' }">得分榜单</el-breadcrumb-item>
                <el-breadcrumb-item>关键词优化</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="optimizeKeyword-table">
            <el-table :data="tableData" style="width: 100%;flex: 1;margin-top: 30px;border: #EEEEEE 1px solid;height: calc(100vh - 300px);overflow-y: auto;" :cell-style="{height: '60px',color:'#343442', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:400}" :header-cell-style="{fontWeight: 500, color: '#14141C', background: '#F3F4FA',height: '60px'}">
                <el-table-column prop="title" label="产品标题" align="center"></el-table-column>
                <el-table-column prop="keyword" label="得分关键词" align="center"></el-table-column>
                <el-table-column prop="score" label="得分" align="center"></el-table-column>
                <el-table-column prop="clickRate" label="点击率" align="center"></el-table-column>
            </el-table>
            <el-pagination
                    class="pages-center"
                    :current-page="adminPages.currentPageNum"
                    :page-size="adminPages.eachPageNum"
                    layout="prev, pager, next, jumper"
                    :total="adminPages.total"
                    @current-change="adminCurrentChange"
            ></el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: "optimizeKeyword",
        data(){
            return {
                tableData:[{
                    title:'阿迪达斯官网adidas DURAMO 9男鞋跑步运动鞋B96578',
                    keyword:'男鞋#跑步#运动鞋#球鞋',
                    score:90,
                    clickRate:'+2%'
                },{
                    title:'阿迪达斯官网adidas DURAMO 9男鞋跑步运动鞋B96578',
                    keyword:'男鞋#跑步#运动鞋#球鞋',
                    score:90,
                    clickRate:'-2%'
                },],
                adminPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                role:null
            }
        },
        created() {
            this.role = Number(localStorage.getItem('role'))
        },
        methods: {
            adminCurrentChange(val) {
                this.adminPages.currentPageNum = val;
                // this.getTaskList()
            },
        }
    }
</script>

<style scoped lang="scss">
    /deep/.pages-center{
        display: flex;
        justify-content: center;
        margin-top: 24px;
    }
    .optimizeKeyword{
        height: 100%;
        padding: 30px;
    }
</style>