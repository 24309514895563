<template>
    <div class="customerService">
        <div class="customerService-header">
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="role == 0">
                <el-breadcrumb-item :to="{ path: '/admin/datacenter' }">数据中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/admin/datacenter' }">得分榜单</el-breadcrumb-item>
                <el-breadcrumb-item>客户服务</el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="role == 1">
                <el-breadcrumb-item :to="{ path: '/schooladmin/datacenter' }">数据中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/schooladmin/datacenter' }">得分榜单</el-breadcrumb-item>
                <el-breadcrumb-item>客户服务</el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb  separator-class="el-icon-arrow-right" v-if="role == 2">
                <el-breadcrumb-item :to="{ path: '/trainadmin/datacenter' }">数据中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/trainadmin/datacenter' }">得分榜单</el-breadcrumb-item>
                <el-breadcrumb-item>客户服务</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="category-table" style="margin-top: 20px">
            <div class="line head">
                <div class="td">机器人</div>
                <div class="td text-center">提问问题</div>
                <div class="td text-center">得分回答</div>
                <div class="td text-center">不得分关键词</div>
                <div class="td text-center">提交答案</div>
                <div class="td text-center" style="width: 15%">得分</div>
                <div class="td text-center" style="width: 15%">平均响应时间</div>
            </div>
            <div class="no-data" v-if="tableData.length === 0">该学生暂无答题数据</div>
            <el-scrollbar class="table-body" v-if="tableData.length > 0" :native="false">
                <div class="one-level" v-for="(oneItem, oneIndex) in tableData" :key="`categoryList_${oneIndex}`">
                    <div class="line body">
                        <div class="td flex align-center">
                            <i class="iconfont down" :class="{current: oneItem.open}" @click="oneItem.open = !oneItem.open">&#xe8f1;</i>
                            <span>{{oneItem.robot_name}}</span>
                        </div>
                        <div class="td flex align-center"></div>
                        <div class="td flex align-center"></div>
                        <div class="td flex align-center"></div>
                        <div class="td flex align-center"></div>
                        <div class="td flex align-center" style="width: 15%">
                            <el-input v-model="oneItem.robot_socre" disabled style="width: 70%; margin-right: 10px"></el-input>分
                        </div>
                        <div class="td flex align-center" style="width: 15%">
                            <el-input v-model="oneItem.stu_avg_response_time" disabled style="width: 70%; margin-right: 10px"></el-input>秒
                        </div>
                    </div>
                    <template v-if="oneItem.content.length > 0">
                        <div class="two-level" v-show="oneItem.open">
                            <div class="line body"
                                 v-for="(twoItem, twoIndex) in oneItem.content" :key="`children_${twoIndex}`">
                                <div class="td flex align-center"></div>
                                <div class="td text-center">
                                    <span>{{twoItem.question}}</span>
                                </div>
                                <div class="td text-center">
                                    <span>{{twoItem.key_word}}</span>
                                </div>
                                <div class="td text-center">
                                    <span>{{twoItem.no_key_word}}</span>
                                </div>
                                <div class="td text-center">
                                    <span v-html="twoItem.stu_key_word"></span>
                                </div>
                                <div class="td text-center" style="width: 15%">
                                    <el-input v-model="twoItem.text_score" disabled style="width: 70%; margin-right: 10px"></el-input>分
                                </div>
                                <div class="td text-center" style="width: 15%"></div>
                            </div>
                        </div>
                    </template>
                </div>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
    export default {
        name: "customerService",
        data(){
            return {
                sort: Number(this.$route.query.sort) || null,
                studentId: Number(this.$route.query.id) || null,
                competitionId: Number(this.$route.query.cId) || null,
                tableData:[],
                role: null
            }
        },
        created() {
            this.role = Number(localStorage.getItem('role'))
        },
        mounted() {
            this.getKefuStuCom()
        },
        methods: {
            getKefuStuCom() {
                let params = {
                    sort: this.sort,
                    stu_id: this.studentId,
                    com_id: this.competitionId
                }
                this.$http.axiosGetBy(this.$api.getOneStuData, params, (res) => {
                    if (res.code === 200) {
                        if (JSON.parse(res.data.data[0].kf_data) === null) {
                            this.tableData = []
                        } else {
                            let tmp = JSON.parse(res.data.data[0].kf_data).com_data
                            this.tableData = tmp.map((item, index) => {
                                if (index === 0) {
                                    item.open = true
                                } else {
                                    item.open = false
                                }
                                return item
                            })
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .customerService{
        height: 100%;
        padding: 30px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
    }
    .el-breadcrumb {
        line-height: 40px;
    }
    .category-table {
        width: 100%;
        height: 1%;
        flex: 1;
        border: 1px solid #eee;
        .line {
            display: flex;
            align-items: stretch;
            border-bottom: 1px solid #eee;
            &.head {
                height: 60px;
                line-height: 40px;
                background: #f2f3fa;
                color: #13131B;
            }
            &.body {
                /*min-height: 60px;*/
                color: #343441;
            }
            .td {
                width: 17.5%;
                padding: 10px 10px;
                border-left: 1px solid #eee;
                &:first-child {
                    border-left: 0;
                }
                .down {
                    cursor: pointer;
                    color: #7D7D7D;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    text-align: center;
                    margin-right: 4px;
                    font-size: 14px;
                    &.current {
                        transform: rotate(90deg);
                    }
                }
                .placeIcon {
                    width: 20px;
                    height: 20px;
                    margin-right: 4px;
                }
                .place24 {
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                }
            }
            &:hover {
                background: #f2f3fa;
            }
        }
        .one-level {
            &:last-child {
                .two-level {
                    .line:last-child {
                        /*border-bottom: 0;*/
                    }
                }
            }
        }
        .two-level {
            transition: all .3s;
        }
        .table-body {
            height: calc(100vh - 327px);
            /deep/ .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }
    }
    /deep/ p {
        margin: 0;
    }
    .align-start {
        align-items: flex-start!important;
    }
    .no-data {
        text-align: center;
        line-height: 60px;
        color: #999;
    }
</style>