<template>
    <div class="shopDecoration">
        <div class="shopDecoration-header">
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="role == 0">
                <el-breadcrumb-item :to="{ path: '/admin/datacenter' }">数据中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/admin/datacenter' }">得分榜单</el-breadcrumb-item>
                <el-breadcrumb-item>店铺装修</el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="role == 1">
                <el-breadcrumb-item :to="{ path: '/schooladmin/datacenter' }">数据中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/schooladmin/datacenter' }">得分榜单</el-breadcrumb-item>
                <el-breadcrumb-item>店铺装修</el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb  separator-class="el-icon-arrow-right" v-if="role == 2">
                <el-breadcrumb-item :to="{ path: '/trainadmin/datacenter' }">数据中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/trainadmin/datacenter' }">得分榜单</el-breadcrumb-item>
                <el-breadcrumb-item>店铺装修</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <el-table :data="tableData" border style="width: 100%; margin-top: 20px; flex: 1" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#13131B', background: '#F2F3FA'}"
                  :cell-style="{fontSize: '14px',color: '#343441'}">
            <el-table-column label="考试内容" align="center" width="200">
                <template slot-scope="scope">
                    <span>{{scope.row.title}}</span>
                </template>
            </el-table-column>
            <el-table-column label="答案概要" align="center">
                <template slot-scope="scope">
                    <div class="good-cover-wrapper">
                        <div class="good-cover" v-if="Array.isArray(scope.row.answer)">
                            <router-link :to="{path: '/student/store/gooddetail', query: {id: item.id}}" target="_blank" class="item" v-for="item in scope.row.answer">
                                <img :src="item.url" alt="">
                            </router-link>
                        </div>
                        <span v-else-if="scope.row.answer.indexOf('http:')">{{scope.row.answer}}</span>
                        <div v-else class="good-cover">
                            <div v-if="scope.row.title === '店招'" class="dianzhao">
                                <img :src="scope.row.answer" alt="">
                            </div>
                            <div v-if="scope.row.title === '电脑海报'" class="banner">
                                <img :src="scope.row.answer" alt="">
                            </div>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="200">
                <template slot-scope="scope">
                    <router-link tag="a" :to="{path: '/student/trainmanage/store', query: {
                        id: Number($route.query.id), cId: Number($route.query.cId), xlId: Number($route.query.xlId)}}" target="_blank">
                        <el-button class="look-detail" type="text">查看详情</el-button>
                    </router-link>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    export default {
        name: "shopDecoration",
        data(){
            return {
                trainId: Number(this.$route.query.xlId) || null,
                studentId: Number(this.$route.query.id) || null,
                competitionId: Number(this.$route.query.cId) || null,
                tableData:[],
                role: null,
            }
        },
        created() {
            this.role = Number(localStorage.getItem('role'))
        },
        mounted() {
            this.getStudentDetail();
        },
        methods: {
            getStudentDetail() {
                let params = {
                    ts_id: this.trainId,
                    user_id: this.studentId,
                    competition_id: this.competitionId
                }
                this.$http.axiosGetBy(this.$api.decorate_studentZxTrainAnswer, params, (res) => {
                    if (res.code === 200) {
                        let tmp = {
                            title: '',
                            answer: ''
                        }
                        for (let key in res.data) {
                            if (key === 'banner') {
                                tmp = {
                                    title: '电脑海报',
                                    answer: res.data.banner.url
                                }
                                this.tableData.push(tmp)
                            } else if (key === 'class') {
                                tmp = {
                                    title: '店铺分类',
                                    answer: res.data.class.class_name
                                }
                                this.tableData.push(tmp)
                            } else if (key === 'dianzhao') {
                                tmp = {
                                    title: '店招',
                                    answer: res.data.dianzhao.url
                                }
                                this.tableData.push(tmp)
                            } else if (key === 'goods') {
                                if (Array.isArray(res.data.goods.goodsInfo)) {
                                    let arr = []
                                    for (let i = 0; i < 5 && i < res.data.goods.goodsInfo.length; i++) {
                                        let itemTmp = {
                                            id: res.data.goods.goodsInfo[i].id,
                                            url: res.data.goods.goodsInfo[i].url
                                        }
                                        arr.push(itemTmp)
                                    }
                                    tmp = {
                                        title: '商品主图',
                                        answer: arr,
                                    }
                                } else {
                                    tmp = {
                                        title: '商品主图',
                                        answer: res.data.goods.goodsInfo,
                                    }
                                }
                                this.tableData.push(tmp)
                            }
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .look-detail {
        color: #0200D8;
        &:hover {
            color: #5864e4;
        }
    }
    /deep/.el-dialog__header{
        background: #0200D8;
        span{
            color: #FEFEFE;
            font-weight:400;
            font-size: 16px;
            /*line-height: 30px;*/
        }
    }
    /deep/.el-dialog__close{
        color: #FFFFFF;
        font-size: 14px;
    }
    /deep/.pages-center{
        display: flex;
        justify-content: center;
        margin-top: 24px;
    }
    /deep/.cell{
        line-height: 1;
    }
    /deep/.answer{
        display: inline-block;
        line-height: 1;
    }
    /deep/.answerImg{
        display: inline-block;
        max-height: 43px;
        border: none;
    }
    /deep/.el-carousel__arrow{
        width: 60px;
        height: 60px;
        font-size: 25px;
        background: #000000;
        opacity: 0.5;
    }
    /deep/.el-carousel__indicators--outside{
    }
    /deep/.el-carousel__indicator .el-carousel__button{
        width: 20px;
        height: 8px;
        opacity: 1;
        background:#ffffff;
        border-radius:4px;
        border:1px solid rgba(255,255,255,1);
    }
    /deep/.is-active .el-carousel__button{
        background: #0200D8;
        border: #0200D8 1px solid;
    }
    /deep/.el-carousel__item{
        display: flex;
        align-items: center;
        justify-content: center;
        /*height: 556px;*/
    }
    /deep/.el-carousel__container{
        height: 556px;
    }
    .shopDecoration {
        height: 100%;
        padding: 30px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        .detail-cover{
            width: 100%;
            height: 100%;
            position: fixed;
            background: rgba(0,0,0,0.3);
            left: 0;
            top: 0;
            z-index: 99;
            .cover-box{
                width: 70%;
                margin: 10% auto;
                .cover-item{
                    /*height: 566px;*/
                    /*margin: 0 auto;*/
                }
            }
        }
    }
    .el-breadcrumb {
        line-height: 40px;
    }
    .good-cover-wrapper {
        text-align: center;
        .good-cover {
            display: flex;
            align-items: center;
            justify-content: center;
            .item {
                width: 60px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #fafafa;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .item + .item {
                margin-left: 10px;
            }
            .banner {
                /*width: 80%;*/
                width: 182.857142px;
                height: 0;
                /*padding-bottom: 26.25%;*/
                padding-bottom: 60px;
                position: relative;
                background: #fafafa;
                img {
                    max-width: 100%;
                    max-height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .dianzhao {
                width: 960px;
                height: 0;
                padding-bottom: 60px;
                position: relative;
                background: #fafafa;
                img {
                    max-width: 100%;
                    max-height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
</style>