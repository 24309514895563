<template>
    <div class="analysisReply">
        <div class="analysisReply-header">
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="role == 0">
                <el-breadcrumb-item :to="{ path: '/admin/datacenter' }">数据中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/admin/datacenter' }">得分榜单</el-breadcrumb-item>
                <el-breadcrumb-item>分析答辩</el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="role == 1">
                <el-breadcrumb-item :to="{ path: '/schooladmin/datacenter' }">数据中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/schooladmin/datacenter' }">得分榜单</el-breadcrumb-item>
                <el-breadcrumb-item>分析答辩</el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="role === 2">
                <el-breadcrumb-item :to="{ path: '/trainadmin/datacenter' }">数据中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/trainadmin/datacenter' }">得分榜单</el-breadcrumb-item>
                <el-breadcrumb-item>分析答辩</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-table :data="tableData" border style="width: 100%; margin-top: 20px; flex: 1" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#13131B', background: '#F2F3FA'}"
                  :cell-style="{fontSize: '14px',color: '#343441'}">
            <el-table-column prop="name" label="名称" align="center"></el-table-column>
            <el-table-column prop="file_name" label="答案" align="center"></el-table-column>
            <el-table-column prop="score" label="得分" v-if="role !== 2" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
<!--                    <a class="look-detail" :class="{disabled: scope.row.file_path === ''}"-->
<!--                       :href="scope.row.file_path ? scope.row.file_path : null">下载</a>-->
                    <a v-if="scope.row.file_path" class="look-detail" :href="scope.row.file_path">下载</a>
                    <a v-else class="look-detail disabled">下载</a>
                    <el-button type="text" class="look-detail" @click="toSeeScore(scope.row)">评分</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog title="分析答辩" :visible.sync="dialogTableVisible">
            <el-table :data="storeData" border style="width: 100%;" size="medium"
                      :header-cell-style="{fontWeight: 'normal', color: '#13131B', background: '#F2F3FA'}"
                      :cell-style="{fontSize: '14px',color: '#343441'}">
                <el-table-column property="name" label="评委名称" align="center"></el-table-column>
                <el-table-column property="pw_score" label="分数" align="center"></el-table-column>
                <el-table-column property="create_time" label="评分时间" align="center"></el-table-column>
            </el-table>
            <el-pagination
                    class="pages-center"
                    :current-page="adminPagesTwo.currentPageNum"
                    :page-size="adminPagesTwo.eachPageNum"
                    layout="prev, pager, next, jumper"
                    :total="adminPagesTwo.total"
                    @current-change="adminCurrentChangeTwo($event, currentRow)"
            ></el-pagination>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "analysisReply",
        data(){
            return {
                studentId: Number(this.$route.query.id) || null,
                competitionId: Number(this.$route.query.cId) || null,
                tableData:[],
                dialogTableVisible: false,
                storeData: [],
                adminPagesTwo: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                role: null,
                currentRow: null
            }
        },
        created() {
            this.role = Number(localStorage.getItem('role'))
        },
        mounted() {
            this.getLuyanList();
        },
        methods: {
            // 获取路演列表
            getLuyanList() {
                let params = {
                    user_id: this.studentId,
                    competition_id: this.competitionId
                }
                this.$http.axiosGetBy(this.$api.dataLuyanAnswer, params, (res) => {
                    if (res.code === 200) {
                        let tmp = {}
                        if (res.data.length === 0) {
                            tmp = {
                                name: '分析答辩',
                                file_name: '暂无数据',
                                file_path: '',
                                score: '暂无数据',
                            }
                        } else {
                            tmp = {
                                name: '分析答辩',
                                file_name: res.data.file_name,
                                file_path: res.data.file_path,
                                score: res.data.score,
                            }
                        }
                        this.tableData.push(tmp)
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            toSeeScore(row) {
                this.dialogTableVisible = true;
                let params = {
                    user_id: this.studentId,
                    competition_id: this.competitionId,
                    result_type: 5,
                    page: this.adminPagesTwo.currentPageNum,
                    limit: this.adminPagesTwo.eachPageNum,
                }
                this.$http.axiosGetBy(this.$api.dataPwScore, params, (res) => {
                    if (res.code === 200) {
                        this.storeData = res.data.list
                        this.adminPagesTwo.total = res.data.total
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            adminCurrentChangeTwo(val, row) {
                this.adminPagesTwo.currentPageNum = val;
                this.toSeeScore(row)
            },
        }
    }
</script>

<style scoped lang="scss">
    .look-detail {
        color: #0200D8;
        font-weight: 500;
        &:hover {
            color: #5864e4;
        }
        &.disabled {
            cursor: not-allowed;
            color: #aaa;
        }
    }
    ::v-deep .el-dialog__header{
        background: #0200D8;
        span{
            color: #FEFEFE;
            font-weight:400;
            font-size: 16px;
            /*line-height: 30px;*/
        }
    }
    .look-detail + .look-detail {
        margin-left: 10px;
    }
    ::v-deep .pages-center{
        display: flex;
        justify-content: center;
        margin-top: 24px;
    }
    .analysisReply{
        height: 100%;
        padding: 30px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
    }
    .el-breadcrumb {
        line-height: 40px;
    }
</style>