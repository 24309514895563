<template>
  <div class="dataCenterDetail">
    <el-tabs
      v-model="activeName"
      type="card"
      class="education-content tabs-full"
      @tab-click="handleClick"
      v-if="showEmpty(0)"
    >
      <el-tab-pane v-if="showSmallModule(1)" label="店铺装修" name="shopDecoration">
        <trainShopDecoration v-if="role === 2" />
        <shopDecoration v-else />
      </el-tab-pane>
      <el-tab-pane v-if="showSmallModule(3)" label="客户服务" name="customerService">
        <trainCustomerService v-if="role === 2" ref="trainCustomerService" />
        <customerService v-else ref="customerService" />
      </el-tab-pane>
      <el-tab-pane v-if="showSmallModule(2)" label="运营推广" name="runPromotion">
        <runPromotion :dataId="dataId" ref="trainRunPromotion" />
      </el-tab-pane>
      <el-tab-pane v-if="showSmallModule(4) && isTabShow" label="分析答辩" name="analysisReply">
        <trainAnalysisReply v-if="role === 2" />
        <analysisReply v-else />
      </el-tab-pane>
      <el-tab-pane v-if="showSmallModule(5)" label="理论考试" name="theoryExam">
        <TheoryExam ref="theoryExam" />
      </el-tab-pane>
      <el-tab-pane v-if="showSmallModule(7)" label="客户服务应答与业务处理" name="examStoreCustomer">
        <ExamStoreCustomer ref="examStoreCustomer" />
      </el-tab-pane>
      <el-tab-pane v-if="showSmallModule(6)" label="客服数据分析与应用" name="dataAnalysisService">
        <DataAnalysisService :dataId="dataId" ref="DataAnalysisService" />
      </el-tab-pane>
      <!--            <el-tab-pane label="优化关键词" name="optimizeKeyword">-->
      <!--                <optimizeKeyword />-->
      <!--            </el-tab-pane>-->
    </el-tabs>
    <div v-if="!showEmpty(0)" style="background-color: #ffffff; height: 100%">
      <el-empty description="暂无模块数据" />
    </div>
  </div>
</template>

<script>
import shopDecoration from "./../../components/admin/shopDecoration.vue";
import trainShopDecoration from "../../components/trainAdmin/dataCenter/ShopDecoration.vue";
import customerService from "./../../components/admin/customerService.vue";
import trainCustomerService from "./../../components/trainAdmin/dataCenter/CustomerService.vue";
import runPromotion from "./../../components/admin/runPromotion.vue";
import analysisReply from "./../../components/admin/analysisReply.vue";
import trainAnalysisReply from "./../../components/trainAdmin/dataCenter/AnalysisReply.vue";
import optimizeKeyword from "./../../components/admin/optimizeKeyword.vue";
import TheoryExam from "../../components/admin/TheoryExam.vue";
import DataAnalysisService from "../../components/admin/dataAnalysisService.vue";
import ExamStoreCustomer from "../../components/admin/ExamStoreCustomer.vue";
import {dataExamModule} from '@/utils/apis'
export default {
  name: "dataCenterDetail",
  components: {
    shopDecoration,
    trainShopDecoration,
    customerService,
    trainCustomerService,
    runPromotion,
    analysisReply,
    trainAnalysisReply,
    optimizeKeyword,
    TheoryExam,
    ExamStoreCustomer,
    DataAnalysisService,
  },
  data() {
    return {
      role: Number(localStorage.getItem("role")),
      activeName: "shopDecoration",
      dataId: {},
      isTabShow: true,
      showModuleArr: [],
      moduleNamaArr: ['shopDecoration', 'runPromotion', 'customerService', 'analysisReply', 'theoryExam', 'dataAnalysisService', 'examStoreCustomer']
    };
  },
  created() {
    this.dataId.cId = this.$route.query.cId;
    this.dataId.id = this.$route.query.id;
    this.dataId.sort = this.$route.query.sort;
    this.dataId.xlId = this.$route.query.xlId;
    this.getPwModulesInfo();
    this.dataExamModuleFn(this.dataId.cId)
  },
  computed: {
    
    showEmpty() {
      return (val) => {
        return this.showModuleArr.length !== val
      }
    },
    showSmallModule() {
      return (val) => {
        return this.showModuleArr.includes(val)
      }
    }
  },
  methods: {
    async dataExamModuleFn(id) {
      try {
        let {code, data} = await dataExamModule({
          id: id
        })
        if(code === 200) {
          this.showModuleArr = data
          this.showModuleArr.sort()
          this.activeName = this.moduleNamaArr[this.showModuleArr[0]-1]
        } else {
          return false;
        }
      } catch (e) {
        console.log(e)
      }
    },
    handleClick(tab, event) {
      if (tab.name === "customerService") {
        if (this.role === 2) {
          this.$refs.trainCustomerService.getKefuStuCom();
        } else {
          this.$refs.customerService.getKefuStuCom();
        }
      } else if (tab.name === "runPromotion") {
        if (this.role === 2) {
          this.$refs.trainRunPromotion.getScore();
        } else {
          this.$refs.trainRunPromotion.getAdminScore();
        }
      }
    },
    getPwModulesInfo() {
      this.$http.axiosGetBy(
        this.$api.decorationModule,
        { competition_id: this.$route.query.cId },
        (res) => {
          // console.log(78,res);
          if (res.code === 200) {
            if (res.data.luyan) {
              this.isTabShow = true;
            } else {
              this.isTabShow = false;
            }
          } else {
            this.$message.warning(res.msg);
          }
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-tabs__content {
  /*box-shadow:0px 0px 16px 1px rgba(62,58,234,0.1);*/
  /*border-radius:0px 10px 20px 10px;*/
}

.dataCenterDetail {
  height: 100%;
  .education-content {
    height: 100%;
  }
}
</style>
