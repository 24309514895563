<template>
    <el-scrollbar class="runPromotion">
        <div class="shopDecoration-header">
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="role === 2">
                <el-breadcrumb-item :to="{ path: '/trainadmin/datacenter' }">数据中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/trainadmin/datacenter' }">得分榜单</el-breadcrumb-item>
                <el-breadcrumb-item>运营推广</el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="role == 0">
                <el-breadcrumb-item :to="{ path: '/admin/datacenter' }">数据中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/admin/datacenter' }">得分榜单</el-breadcrumb-item>
                <el-breadcrumb-item>运营推广</el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb separator-class="el-icon-arrow-right" v-if="role == 1">
                <el-breadcrumb-item :to="{ path: '/schooladmin/datacenter' }">数据中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/schooladmin/datacenter' }">得分榜单</el-breadcrumb-item>
                <el-breadcrumb-item>运营推广</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="examContent" v-if="role != 2">
            <div class="contentHeader">
                <div class="contentItem">考试内容</div>
                <div class="contentItem">商品信息</div>
                <div class="contentItem" style="width: 10%;">展现数</div>
                <div class="contentItem" style="width: 10%;">点击数</div>
                <div class="contentItem" style="width: 10%;">转化数</div>
                <div class="contentItem" style="width: 10%;">利润</div>
                <div class="contentItem" style="width: 10%;">排名</div>
                <div class="contentItem" style="width: 10%; text-align: center">得分({{storeSelection_percent+'%'}})</div>
            </div>
            <div class="contentForBox" v-if="storeSelectionData.length">
                <div class="contentBody">
                    <div class="contentBodyItem">
                        <i class="iconfont" @click="isSearchAdvertising = !isSearchAdvertising">{{isSearchAdvertising ? '&#xe633;' : '&#xec86;'}}</i>
                        <span>店铺选品</span>
                    </div>
                    <div class="contentBodyItem"></div>
                    <div class="contentBodyItem" style="width: 10%;">{{storeSelection.display_number}}</div>
                    <div class="contentBodyItem" style="width: 10%;">{{storeSelection.click_number}}</div>
                    <div class="contentBodyItem" style="width: 10%;">{{storeSelection.conversion_num}}</div>
                    <div class="contentBodyItem" style="width: 10%;">{{storeSelection.profit}}</div>
                    <div class="contentBodyItem" style="width: 10%;">
                        利润（第{{storeSelection.ranking}}名）
                    </div>
                    <div class="contentBodyItem" style="width: 10%; text-align: center">
                        <span class="fangKuang">{{storeSelection.score.toFixed(1)}}</span>分
                    </div>
                </div>
                <div class="contentFooter" v-if="isSearchAdvertising" v-for="(item,index) in storeSelectionData" :key="index">
                    <div class="contentFooterItem"></div>
                    <div class="contentFooterItem text-overflow"><span>{{item.name}}</span></div>
                    <div class="contentFooterItem" style="width: 10%;">{{item.display_number}}</div>
                    <div class="contentFooterItem" style="width: 10%;">{{item.click_number}}</div>
                    <div class="contentFooterItem" style="width: 10%;">{{item.conversion_num}}</div>
                    <div class="contentFooterItem" style="width: 10%;">{{Number(item.profit).toFixed(2) || 0}}</div>
                    <div class="contentFooterItem" style="width: 10%;"></div>
                    <div class="contentFooterItem" style="width: 10%;"></div>
                </div>
            </div>
            <div class="noData" v-else>暂无数据</div>
        </div>

        <div class="examContent" v-if="role == 2">
            <div class="contentHeader">
                <div class="contentItem">考试内容</div>
                <div class="contentItem">商品信息</div>
                <div class="contentItem">展现数</div>
                <div class="contentItem">点击数</div>
                <div class="contentItem">转化数</div>
                <div class="contentItem">利润</div>
            </div>
            <div class="contentForBox" v-if="selectModule.length">
                <div class="contentBody">
                    <div class="contentBodyItem">
                        <i class="iconfont" @click="isSearchAdvertising = !isSearchAdvertising">{{isSearchAdvertising ? '&#xe633;' : '&#xec86;'}}</i>
                        <span>店铺选品</span>
                    </div>
                    <div class="contentBodyItem"></div>
                    <div class="contentBodyItem text-overflow">{{display_number}}</div>
                    <div class="contentBodyItem">{{click_number}}</div>
                    <div class="contentBodyItem">{{conversion_num}}</div>
                    <div class="contentBodyItem">{{Number(profit).toFixed(2) || profit}}</div>
                </div>
                <div class="contentFooter" v-if="isSearchAdvertising" v-for="(item,index) in selectModule" :key="index">
                    <div class="contentFooterItem"></div>
                    <div class="contentFooterItem text-overflow"><span>{{item.name}}</span></div>
                    <div class="contentFooterItem">{{item.display_number}}</div>
                    <div class="contentFooterItem">{{item.click_number}}</div>
                    <div class="contentFooterItem">{{item.conversion_num}}</div>
                    <div class="contentFooterItem">{{Number(item.profit).toFixed(2) || item.profit}}</div>
                </div>
            </div>
            <div class="noData" v-else>暂无数据</div>
        </div>

        <div class="storeMarketing" v-if="role != 2">
            <div class="marketingHeader">
                <div class="marketingItem">考试内容</div>
                <div class="marketingItem">活动类型</div>
                <div class="marketingItem">优惠商品</div>
                <div class="marketingItem">优惠内容</div>
                <div class="marketingItem">得分({{searchOpStuScore_percent+'%'}})</div>
            </div>
            <div class="marketingForBox" v-if="stuMarketData.length">
                <div class="marketingBody">
                    <div class="marketingBodyItem">
                        <i class="iconfont" @click="isMarketing = !isMarketing">{{isMarketing ? '&#xe633;' : '&#xec86;'}}</i>
                        <span>店铺营销</span>
                    </div>
                    <div class="marketingBodyItem"></div>
                    <div class="marketingBodyItem"></div>
                    <div class="marketingBodyItem"></div>
                    <div class="marketingBodyItem">
                        <span>{{stuMarketTotalScore * (searchOpStuScore_percent/100)}}</span>分
                    </div>
                </div>
                <div class="marketingFooter" v-if="isMarketing">
                    <div class="marketingFooter" v-for="(item,index) in stuMarketData" :key="index">
                        <div class="marketingFooter2" v-for="(item2,index2) in item.activity_data.data" :key="index2" v-if="item2.goods_data">
                            <div class="marketingFooterItem"></div>
                            <div class="marketingFooterItem">{{index2 === 0 ? item.type : ''}}</div>
                            <div class="marketingFooterItem text-overflow">{{item2.goods_data.name}}</div>
                            <div class="marketingFooterItem text-overflow">{{item.activity_detail}}</div>
                            <div class="marketingFooterItem" v-if="index2 === 0">
                                <span >{{(item.score * (searchOpStuScore_percent/100)).toFixed(1)}}</span>分
                            </div>
                            <div class="marketingFooterItem" v-else>
                            </div>
                        </div>
                        <div class="marketingFooter2" v-for="(item3,index3) in item.activity_data.data[0]" :key="index3" v-if="item3.goods_data">
                            <div class="marketingFooterItem"></div>
                            <div class="marketingFooterItem">{{ index3 === 0 ? item.type : ''}}</div>
                            <div class="marketingFooterItem text-overflow">{{item3.goods_data.name}}</div>
                            <div class="marketingFooterItem text-overflow">{{item.activity_detail}}</div>
                            <div class="marketingFooterItem" v-if="index3 === 0">
                                <span>{{item.score.toFixed(1)}}</span>分
                            </div>
                            <div class="marketingFooterItem" v-else>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="noData" v-else>暂无数据</div>
        </div>

        <div class="storeMarketing" v-if="role == 2">
            <div class="marketingHeader">
                <div class="marketingItem">考试内容</div>
                <div class="marketingItem">活动类型</div>
                <div class="marketingItem">优惠商品</div>
                <div class="marketingItem">优惠内容</div>
                <div class="marketingItem">得分</div>
            </div>
            <div class="marketingForBox" v-if="marketModule.length">
                <div class="marketingBody">
                    <div class="marketingBodyItem">
                        <i class="iconfont" @click="isMarketing = !isMarketing">{{isMarketing ? '&#xe633;' : '&#xec86;'}}</i>
                        <span>店铺营销</span>
                    </div>
                    <div class="marketingBodyItem"></div>
                    <div class="marketingBodyItem"></div>
                    <div class="marketingBodyItem"></div>
                    <div class="marketingBodyItem">
                        <span>{{marketTotalScore}}</span>分
                    </div>
                </div>
                <div class="marketingFooter" v-if="isMarketing" v-for="(item,index) in marketModule" :key="index">
                    <div class="marketingFooter2" v-for="(item2,index2) in item.activity_data.data" :key="index2" v-if="item2.goods_data">
                        <div class="marketingFooterItem"></div>
                        <div class="marketingFooterItem">{{index2 === 0 ? item.type : ''}}</div>
                        <div class="marketingFooterItem text-overflow">{{item2.goods_data.name}}</div>
                        <div class="marketingFooterItem text-overflow">{{item.activity_detail || '优惠'+item2.yh_sum+'元'}}</div>
                        <div class="marketingFooterItem" v-if="index2 === 0">
                            <span>{{item.score.toFixed(1)}}</span>分
                        </div>
                        <div class="marketingFooterItem" v-else></div>
                    </div>
                    <div class="marketingFooter2" v-for="(item3,index3) in item.activity_data.data[0]" :key="index3" v-if="item3.goods_data">
                        <div class="marketingFooterItem"></div>
                        <div class="marketingFooterItem">{{index3 === 0 ? item.type : ''}}</div>
                        <div class="marketingFooterItem text-overflow">{{item3.goods_data.name}}</div>
                        <div class="marketingFooterItem text-overflow">{{item.activity_detail}}</div>
                        <div class="marketingFooterItem" v-if="index3 === 0">
                            <span>{{item.score.toFixed(1)}}</span>分
                        </div>
                        <div class="marketingFooterItem" v-else></div>
                    </div>
                </div>
            </div>
            <div class="noData" v-else>暂无数据</div>
        </div>

        <div class="boothAdvertising" v-if="role != 2">
            <div class="contentHeader">
                <div class="contentItem">考试内容</div>
                <div class="contentItem">计划名称</div>
                <div class="contentItem">日预算</div>
                <div class="contentItem">展现数</div>
                <div class="contentItem">点击数</div>
                <div class="contentItem">转化数</div>
                <div class="contentItem">总费用</div>
                <div class="contentItem">排名</div>
                <div class="contentItem">得分({{booth_percent+'%'}})</div>
            </div>
            <div class="contentForBox" v-if="boothAdminData.length">
                <div class="contentBody">
                    <div class="contentBodyItem">
                        <i class="iconfont" @click="isSearchAdvertising2 = !isSearchAdvertising2">{{isSearchAdvertising2 ? '&#xe633;' : '&#xec86;'}}</i>
                        <span>展位广告</span>
                    </div>
                    <div class="contentBodyItem"></div>
                    <div class="contentBodyItem">{{parseFloat(boothAdmin.daily_budget) == 0 ? '不限' : parseFloat(boothAdmin.daily_budget)}}</div>
                    <div class="contentBodyItem">{{boothAdmin.display_number}}</div>
                    <div class="contentBodyItem">{{boothAdmin.click_number}}</div>
                    <div class="contentBodyItem">{{boothAdmin.conversion_num}}</div>
                    <div class="contentBodyItem">{{boothAdmin.total_cost}}</div>
                    <div class="contentBodyItem">
                        转化数（第{{boothAdmin.ranking}}名）
                    </div>
                    <div class="contentBodyItem">
                        <span>{{boothAdmin.score.toFixed(1)}}</span>分
                    </div>
                </div>
                <div class="contentFooter" v-if="isSearchAdvertising2" v-for="(item,index) in boothAdminData" :key="index">
                    <div class="contentFooterItem"></div>
                    <div class="contentFooterItem text-overflow"><span>{{item.name}}</span></div>
                    <div class="contentFooterItem">{{parseFloat(item.daily_budget) == 0 ? '不限' : parseFloat(item.daily_budget)}}</div>
                    <div class="contentFooterItem">{{item.display_number}}</div>
                    <div class="contentFooterItem">{{item.click_number}}</div>
                    <div class="contentFooterItem">{{item.conversion_num}}</div>
                    <div class="contentFooterItem">{{Number(item.total_cost).toFixed(2) || item.total_cost}}</div>
                    <div class="contentFooterItem"></div>
                    <div class="contentFooterItem">
                    </div>
                </div>
            </div>
            <div v-else class="noData">暂无数据</div>
        </div>

        <div class="boothAdvertising2" v-if="role == 2">
            <div class="contentHeader">
                <div class="contentItem">考试内容</div>
                <div class="contentItem">计划名称</div>
                <div class="contentItem">日预算</div>
                <div class="contentItem">展现数</div>
                <div class="contentItem">点击数</div>
                <div class="contentItem">转化数</div>
                <div class="contentItem">总费用</div>
            </div>
            <div class="contentForBox" v-if="boothModule.length">
                <div class="contentBody">
                    <div class="contentBodyItem">
                        <i class="iconfont" @click="isSearchAdvertising2 = !isSearchAdvertising2">{{isSearchAdvertising2 ? '&#xe633;' : '&#xec86;'}}</i>
                        <span>展位广告</span>
                    </div>
                    <div class="contentBodyItem"></div>
                    <div class="contentBodyItem">{{parseFloat(daily_budget) == 0 ? '不限' : parseFloat(daily_budget)}}</div>
                    <div class="contentBodyItem">{{display_number2}}</div>
                    <div class="contentBodyItem">{{click_number2}}</div>
                    <div class="contentBodyItem">{{conversion_num2}}</div>
                    <div class="contentBodyItem">{{Number(total_cost).toFixed(2) || item.total_cost}}</div>
                </div>
                <div class="contentFooter" v-if="isSearchAdvertising2" v-for="(item,index) in boothModule" :key="index">
                    <div class="contentFooterItem"></div>
                    <div class="contentFooterItem text-overflow"><span>{{item.name}}</span></div>
                    <div class="contentFooterItem">{{parseFloat(item.daily_budget) == 0 ? '不限' : parseFloat(item.daily_budget)}}</div>
                    <div class="contentFooterItem">{{item.display_number}}</div>
                    <div class="contentFooterItem">{{item.click_number}}</div>
                    <div class="contentFooterItem">{{item.conversion_num}}</div>
                    <div class="contentFooterItem">{{Number(item.total_cost).toFixed(2) || item.total_cost}}</div>
                </div>
            </div>
            <div v-else class="noData">暂无数据</div>
        </div>

        <div class="boothAdvertising" v-if="role!=2">
            <div class="contentHeader">
                <div class="contentItem">考试内容</div>
                <div class="contentItem">计划名称</div>
                <div class="contentItem">日预算</div>
                <div class="contentItem">展现数</div>
                <div class="contentItem">点击数</div>
                <div class="contentItem">转化数</div>
                <div class="contentItem">总费用</div>
                <div class="contentItem">排名</div>
                <div class="contentItem">得分({{searchData_percent+'%'}})</div>
            </div>
            <div class="contentForBox" v-if="searchDataList.length">
                <div class="contentBody">
                    <div class="contentBodyItem">
                        <i class="iconfont" @click="isSearchAdvertising3 = !isSearchAdvertising3">{{isSearchAdvertising3 ? '&#xe633;' : '&#xec86;'}}</i>
                        <span>搜索广告</span>
                    </div>
                    <div class="contentBodyItem"></div>
                    <div class="contentBodyItem">{{this.adminSearchBudgetTotal ? Number(this.adminSearchBudgetTotal).toFixed(2) : '不限' }}</div>
                    <div class="contentBodyItem">{{searchData.show_num}}</div>
                    <div class="contentBodyItem">{{searchData.click_num}}</div>
                    <div class="contentBodyItem">{{searchData.tran_num}}</div>
                    <div class="contentBodyItem">{{Number(searchData.total_cost).toFixed(2) || 0}}</div>
                    <div class="contentBodyItem">
                        点击数（第{{searchData.rank}}名）
                    </div>
                    <div class="contentBodyItem">
                        <span>{{searchData.score.toFixed(1)}}</span>分
                    </div>
                </div>
                <div class="contentFooter" v-if="isSearchAdvertising3" v-for="(item,index) in searchDataList" :key="index">
                    <div class="contentFooterItem"></div>
                    <div class="contentFooterItem text-overflow"><span>{{item.project_name}}</span></div>
                    <div class="contentFooterItem">{{Number(item.daily_budget) ? parseFloat(item.daily_budget) : '不限'}}</div>
                    <div class="contentFooterItem">{{item.show_num || 0}}</div>
                    <div class="contentFooterItem">{{item.click_num || 0}}</div>
                    <div class="contentFooterItem">{{item.tran_num}}</div>
                    <div class="contentFooterItem">{{Number(item.total_cost).toFixed(2) || 0}}</div>
                    <div class="contentFooterItem"></div>
                    <div class="contentFooterItem">
                    </div>
                </div>
            </div>
            <div class="noData" v-else>暂无数据</div>
        </div>
        <div class="boothAdvertising2" v-if="role == 2">
            <div class="contentHeader">
                <div class="contentItem">考试内容</div>
                <div class="contentItem">计划名称</div>
                <div class="contentItem">日预算</div>
                <div class="contentItem">展现数</div>
                <div class="contentItem">点击数</div>
                <div class="contentItem">转化数</div>
                <div class="contentItem">总费用</div>
            </div>
            <div class="contentForBox" v-if="searchModuleData.length">
                <div class="contentBody">
                    <div class="contentBodyItem">
                        <i class="iconfont" @click="isSearchAdvertising4 = !isSearchAdvertising4">{{isSearchAdvertising4 ? '&#xe633;' : '&#xec86;'}}</i>
                        <span>搜索广告</span>
                    </div>
                    <div class="contentBodyItem"></div>
                    <div class="contentBodyItem">{{Number(searchModule.daily_budget) ? parseFloat(searchModule.daily_budget): '不限'}}</div>
                    <div class="contentBodyItem">{{searchModule.show_num}}</div>
                    <div class="contentBodyItem">{{searchModule.click_num}}</div>
                    <div class="contentBodyItem">{{searchModule.tran_num}}</div>
                    <div class="contentBodyItem">{{Number(searchModule.total_cost).toFixed(2) || 0}}</div>
                </div>
                <div class="contentFooter" v-if="isSearchAdvertising4" v-for="(item,index) in searchModuleData" :key="index">
                    <div class="contentFooterItem"></div>
                    <div class="contentFooterItem">{{item.project_name}}</div>
                    <div class="contentFooterItem">{{Number(item.daily_budget) ? parseFloat(item.daily_budget) : '不限'}}</div>
                    <div class="contentFooterItem">{{item.show_num || 0}}</div>
                    <div class="contentFooterItem">{{item.click_num || 0}}</div>
                    <div class="contentFooterItem">{{item.tran_num}}</div>
                    <div class="contentFooterItem">{{Number(item.total_cost).toFixed(2) || 0}}</div>
                </div>
            </div>
            <div v-else class="noData">暂无数据</div>
        </div>
    </el-scrollbar>
</template>

<script>
    export default {
        name: "runPromotion",
        data(){
            return {
                isSearchAdvertising4:false,
                isSearchAdvertising:true,
                isMarketing:true,
                isSearchAdvertising2:true,
                isSearchAdvertising3:true,
                boothData:[],
                marketModule:[],
                marketTotalScore:'',
                selectModule:[],
                click_number:'',
                conversion_num:'',
                display_number:'',
                profit:0,
                boothModule:[],
                click_number2:'',
                conversion_num2:'',
                daily_budget:'',
                display_number2:'',
                total_cost:'',
                storeSelection:{},
                storeSelectionData:[],
                boothAdmin:{},
                boothAdminData:[],
                stuMarket:{},
                stuMarketData:[],
                stuMarketTotalScore:0,
                role:null,
                searchData:{},
                searchDataList:[],
                searchModule:{},
                searchModuleData:[],
                booth_percent:'',
                searchData_percent:'',
                searchOpStuScore_percent:'',
                storeSelection_percent:'',
                adminSearchBudgetTotal:0,
            }
        },
        props:{
            dataId:Object
        },
        created() {
            // this.getScore();
            this.getAdminScore();
            // console.log(321,this.$route.query)
            this.role = Number(localStorage.getItem('role'))
        },
        methods: {
            getAdminScore(){
                // console.log(346,this.$route.query)
              let param = {
                  id:this.$route.query.rId,
                  competition_id:this.$route.query.cId
              }
              this.$http.axiosGetBy(this.$api.score, param, res=>{
                  // console.log(324,res);
                  if(res.code === 200){
                      this.booth_percent = res.data.booth_percent ? res.data.booth_percent : 0;
                      this.searchData_percent = res.data.searchData_percent ? res.data.searchData_percent : 0;
                      this.searchOpStuScore_percent = res.data.searchOpStuScore_percent ? res.data.searchOpStuScore_percent : 0;
                      this.storeSelection_percent = res.data.storeSelection_percent ? res.data.storeSelection_percent : 0;
                      this.getShowStuMarket();
                      if(res.data.storeSelection.data){
                          this.storeSelection = res.data.storeSelection;
                          this.storeSelectionData = res.data.storeSelection.data;
                      }
                      if(res.data.booth.data){
                          this.boothAdmin = res.data.booth;
                          this.boothAdminData = res.data.booth.data;
                      }
                      if(res.data.searchData.express_list){
                          this.searchData = res.data.searchData;
                          this.searchDataList = res.data.searchData.express_list;
                          let num = 0;
                          this.searchDataList.map(item=>{
                              num += Number(item.daily_budget)
                          })
                          this.adminSearchBudgetTotal = num;
                      }
                  } else {
                      this.$message.warning(res.msg)
                  }
              })
            },
            getShowStuMarket(){
                // console.log(372,id)
                this.$http.axiosGetBy(this.$api.showStuMarket,{stu_id:this.$route.query.id,competition_id:this.$route.query.cId,display:'all'}, res=>{
                    // console.log(347,res)
                    if(res.code === 200){
                        if(res.data.length){
                            if(res.data.length){
                                this.stuMarket = res.data[0];
                                this.stuMarketData = res.data[0].stu_market_data;
                                this.stuMarketTotalScore = res.data[0].total_score;
                            }
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            getScore(){
                //获取学生所有训练的记录
                // console.log(this.$route.query)
                let param = {
                    stu_id:this.$route.query.id,
                    com_id:this.$route.query.cId,
                    sort:this.$route.query.sort
                }
                this.$http.axiosGetBy(this.$api.getOneStuData, param, res=>{
                    // console.log(236,res)
                    if(res.code === 200){
                        // console.log(279,JSON.parse(res.data.data[0].operation_data));
                        if(JSON.parse(res.data.data[0].operation_data).market_module!=null){
                            this.marketModule = JSON.parse(res.data.data[0].operation_data).market_module.children;
                            this.marketTotalScore = JSON.parse(res.data.data[0].operation_data).market_module.total_score || 0;
                        }
                        if(JSON.parse(res.data.data[0].operation_data).select_module){
                            this.selectModule = JSON.parse(res.data.data[0].operation_data).select_module.children;
                            this.click_number = JSON.parse(res.data.data[0].operation_data).select_module.click_number;
                            this.conversion_num = JSON.parse(res.data.data[0].operation_data).select_module.conversion_num;
                            this.display_number = JSON.parse(res.data.data[0].operation_data).select_module.display_number;
                            // this.selectModule.map(item=>{
                            //     this.profit += item.profit
                            // })
                            this.profit = JSON.parse(res.data.data[0].operation_data).select_module.profit;
                            // console.log(217,this.selectModule)
                        }
                        if(JSON.parse(res.data.data[0].operation_data).booth_module){
                            this.boothModule = JSON.parse(res.data.data[0].operation_data).booth_module.children;
                            this.click_number2 = JSON.parse(res.data.data[0].operation_data).booth_module.click_number;
                            this.conversion_num2 = JSON.parse(res.data.data[0].operation_data).booth_module.conversion_num;
                            this.daily_budget = JSON.parse(res.data.data[0].operation_data).booth_module.daily_budget;
                            this.display_number2 = JSON.parse(res.data.data[0].operation_data).booth_module.display_number;
                            this.total_cost = JSON.parse(res.data.data[0].operation_data).booth_module.total_cost;
                        }
                        if(JSON.parse(res.data.data[0].operation_data).search_module){
                            this.searchModule = JSON.parse(res.data.data[0].operation_data).search_module;
                            this.searchModuleData = JSON.parse(res.data.data[0].operation_data).search_module.children;
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            adminCurrentChange(val) {
                this.adminPages.currentPageNum = val;
                // this.getTaskList()
            },
        }
    }
</script>

<style scoped lang="scss">
    .noData{
        height: 60px;
        line-height: 60px;
        text-align: center;
    }
    /deep/.el-breadcrumb {
        line-height: 40px;
    }
    .runPromotion{
        height: 100%;
        /deep/ .el-scrollbar__wrap {
            overflow-x: hidden;
            .el-scrollbar__view {
                padding: 30px;
            }
        }
            .examContent{
                margin-top: 20px;
                border: 1px solid #EEEEEE;
                color: #333333;
                font-size: 14px;
                font-weight: 500;
                .contentHeader{
                    display: flex;
                    background: #F2F3FA;
                    height: 60px;
                    line-height: 60px;
                    .contentItem:nth-child(1){
                        text-align: left;
                        padding-left: 26px;
                        width: 8%;
                    }
                    .contentItem:nth-child(2){
                        text-align: left;
                        width: 32%;
                    }
                    .contentItem:nth-child(3){
                        text-align: center;
                        width: 12%;
                    }
                    .contentItem:nth-child(4){
                        text-align: center;
                        width: 12%;
                    }
                    .contentItem:nth-child(5){
                        text-align: center;
                        width: 12%;
                    }
                    .contentItem:nth-child(6){
                        text-align: center;
                        width: 12%;
                    }
                    .contentItem:nth-child(7){
                        text-align: center;
                        width: 12%;
                    }
                }
                .contentBody{
                    display: flex;
                    height: 60px;
                    line-height: 60px;
                    border-bottom: 1px solid #EEEEEE;
                    .contentBodyItem:nth-child(1){
                        text-align: left;
                        padding-left: 26px;
                        width: 8%;
                        i{
                            cursor: pointer;
                            color: #7D7D7D;
                            font-size: 14px;
                            margin-right: 5px;
                        }
                    }
                    .contentBodyItem:nth-child(2){
                        text-align: left;
                        width: 32%;
                    }
                    .contentBodyItem:nth-child(3){
                        text-align: center;
                        width: 12%;
                    }
                    .contentBodyItem:nth-child(4){
                        text-align: center;
                        width: 12%;
                    }
                    .contentBodyItem:nth-child(5){
                        text-align: center;
                        width: 12%;
                    }
                    .contentBodyItem:nth-child(6){
                        text-align: center;
                        width: 12%;
                    }
                    .contentBodyItem:nth-child(7){
                        text-align: center;
                        width: 12%;
                        span{
                            border: 1px solid #D1D2DB;
                            display: inline-block;
                            width: 68px;
                            height: 38px;
                            line-height: 38px;
                            border-radius: 5px;
                            padding-left: 14px;
                            margin-right: 6px;
                            text-align: left;
                        }
                    }
                    .fangKuang{
                        border: 1px solid #D1D2DB;
                        display: inline-block;
                        width: 68px;
                        height: 38px;
                        line-height: 38px;
                        border-radius: 5px;
                        padding-left: 14px;
                        margin-right: 6px;
                        text-align: left;
                    }
                }
                .contentFooter{
                    display: flex;
                    height: 80px;
                    line-height: 80px;
                    border-bottom: 1px solid #EEEEEE;
                    contentFooterItem{
                        padding: 0 20px;
                    }
                    .contentFooterItem:nth-child(1){
                        text-align: left;
                        padding-left: 26px;
                        width: 8%;
                    }
                    .contentFooterItem:nth-child(2){
                        text-align: left;
                        width: 32%;
                        span{
                            padding: 0 20px;
                        }
                    }
                    .contentFooterItem:nth-child(3){
                        text-align: center;
                        width: 12%;
                    }
                    .contentFooterItem:nth-child(4){
                        text-align: center;
                        width: 12%;
                    }
                    .contentFooterItem:nth-child(5){
                        text-align: center;
                        width: 12%;
                    }
                    .contentFooterItem:nth-child(6){
                        text-align: center;
                        width: 12%;
                    }
                    .contentFooterItem:nth-child(7){
                        text-align: center;
                        width: 12%;
                        span{
                            border: 1px solid #D1D2DB;
                            display: inline-block;
                            width: 68px;
                            height: 38px;
                            line-height: 38px;
                            border-radius: 5px;
                            padding-left: 14px;
                            margin-right: 6px;
                            text-align: left;
                        }
                    }
                }
            }
        .storeMarketing{
            margin-top: 30px;
            border: 1px solid #EEEEEE;
            color: #333333;
            font-size: 14px;
            font-weight: 500;
            .marketingHeader{
                display: flex;
                background: #F2F3FA;
                height: 60px;
                line-height: 60px;
                .marketingItem:nth-child(1){
                    text-align: left;
                    padding-left: 26px;
                    width: 8%;
                }
                .marketingItem:nth-child(2){
                    text-align: left;
                    width: 12%;
                }
                .marketingItem:nth-child(3){
                    text-align: left;
                    width: 29%;
                }
                .marketingItem:nth-child(4){
                    text-align: left;
                    width: 27%;
                }
                .marketingItem:nth-child(5){
                    text-align: left;
                    width: 22%;
                }
            }
            .marketingBody{
                display: flex;
                height: 60px;
                line-height: 60px;
                border-bottom: 1px solid #EEEEEE;
                .marketingBodyItem:nth-child(1){
                    text-align: left;
                    padding-left: 26px;
                    width: 8%;
                    i{
                        cursor: pointer;
                        color: #7D7D7D;
                        font-size: 14px;
                        margin-right: 5px;
                    }
                }
                .marketingBodyItem:nth-child(2){
                    text-align: left;
                    width: 12%;
                }
                .marketingBodyItem:nth-child(3){
                    text-align: left;
                    width: 29%;
                }
                .marketingBodyItem:nth-child(4){
                    text-align: left;
                    width: 27%;
                }
                .marketingBodyItem:nth-child(5){
                    text-align: left;
                    width: 22%;
                    span{
                        border: 1px solid #D1D2DB;
                        display: inline-block;
                        width: 82px;
                        height: 38px;
                        line-height: 38px;
                        border-radius: 5px;
                        padding-left: 14px;
                        margin-right: 6px;
                    }
                }
            }
            .marketingFooter{
                .marketingFooter2{
                    display: flex;
                    height: 80px;
                    line-height: 80px;
                    border-bottom: 1px solid #EEEEEE;
                }
                .marketingFooterItem:nth-child(1){
                    text-align: left;
                    padding-left: 26px;
                    width: 8%;
                }
                .marketingFooterItem:nth-child(2){
                    text-align: left;
                    width: 12%;
                }
                .marketingFooterItem:nth-child(3){
                    text-align: left;
                    width: 29%;
                }
                .marketingFooterItem:nth-child(4){
                    text-align: left;
                    width: 27%;
                }
                .marketingFooterItem:nth-child(5){
                    text-align: left;
                    width: 22%;
                    span{
                        border: 1px solid #D1D2DB;
                        display: inline-block;
                        width: 82px;
                        height: 38px;
                        line-height: 38px;
                        border-radius: 5px;
                        padding-left: 14px;
                        margin-right: 6px;
                    }
                }
            }
        }
        .boothAdvertising{
            margin-top: 30px;
            border: 1px solid #EEEEEE;
            color: #333333;
            font-size: 14px;
            font-weight: 500;
            .contentHeader{
                display: flex;
                background: #F2F3FA;
                height: 60px;
                line-height: 60px;
                .contentItem:nth-child(1){
                    text-align: left;
                    padding-left: 26px;
                    width: 8%;
                }
                .contentItem:nth-child(2){
                    text-align: left;
                    width: 28%;
                }
                .contentItem:nth-child(3){
                    text-align: center;
                    width: 8%;
                }
                .contentItem:nth-child(4){
                    text-align: center;
                    width: 8%;
                }
                .contentItem:nth-child(5){
                    text-align: center;
                    width: 8%;
                }
                .contentItem:nth-child(6){
                    text-align: center;
                    width: 8%;
                }
                .contentItem:nth-child(7){
                    text-align: center;
                    width: 8%;
                }
                .contentItem:nth-child(8){
                    text-align: center;
                    width: 8%;
                }
                .contentItem:nth-child(9){
                    text-align: center;
                    width: 10%;
                }
            }
            .contentBody{
                display: flex;
                height: 60px;
                line-height: 60px;
                border-bottom: 1px solid #EEEEEE;
                .contentBodyItem:nth-child(1){
                    text-align: left;
                    padding-left: 26px;
                    width: 8%;
                    i{
                        cursor: pointer;
                        color: #7D7D7D;
                        font-size: 14px;
                        margin-right: 5px;
                    }
                }
                .contentBodyItem:nth-child(2){
                    text-align: left;
                    width: 28%;
                }
                .contentBodyItem:nth-child(3){
                    text-align: center;
                    width: 8%;
                }
                .contentBodyItem:nth-child(4){
                    text-align: center;
                    width: 8%;
                }
                .contentBodyItem:nth-child(5){
                    text-align: center;
                    width: 8%;
                }
                .contentBodyItem:nth-child(6){
                    text-align: center;
                    width: 8%;
                }
                .contentBodyItem:nth-child(7){
                    text-align: center;
                    width: 8%;
                    span{
                        border: 1px solid #D1D2DB;
                        display: inline-block;
                        width: 68px;
                        height: 38px;
                        line-height: 38px;
                        border-radius: 5px;
                        padding-left: 14px;
                        margin-right: 6px;
                        text-align: left;
                    }
                }
                .contentBodyItem:nth-child(8){
                    text-align: center;
                    width: 8%;
                }
                .contentBodyItem:nth-child(9){
                    text-align: center;
                    width: 10%;
                    span{
                        border: 1px solid #D1D2DB;
                        display: inline-block;
                        width: 68px;
                        height: 38px;
                        line-height: 38px;
                        border-radius: 5px;
                        padding-left: 14px;
                        margin-right: 6px;
                        text-align: left;
                    }
                }
            }
            .contentFooter{
                display: flex;
                height: 80px;
                line-height: 80px;
                border-bottom: 1px solid #EEEEEE;
                .contentFooterItem:nth-child(1){
                    text-align: left;
                    padding-left: 26px;
                    width: 8%;
                }
                .contentFooterItem:nth-child(2){
                    text-align: left;
                    width: 28%;
                }
                .contentFooterItem:nth-child(3){
                    text-align: center;
                    width: 8%;
                }
                .contentFooterItem:nth-child(4){
                    text-align: center;
                    width: 8%;
                }
                .contentFooterItem:nth-child(5){
                    text-align: center;
                    width: 8%;
                }
                .contentFooterItem:nth-child(6){
                    text-align: center;
                    width: 8%;
                }
                .contentFooterItem:nth-child(7){
                    text-align: center;
                    width: 8%;

                }
                .contentFooterItem:nth-child(8){
                    text-align: center;
                    width: 8%;
                }
                .contentFooterItem:nth-child(9){
                    text-align: center;
                    width: 10%;
                    span{
                        border: 1px solid #D1D2DB;
                        display: inline-block;
                        width: 68px;
                        height: 38px;
                        line-height: 38px;
                        border-radius: 5px;
                        padding-left: 14px;
                        margin-right: 6px;
                        text-align: left;
                    }
                }
            }
        }
    }
    .el-breadcrumb {
        line-height: 40px;
    }
    .boothAdvertising2{
        margin-top: 30px;
        border: 1px solid #EEEEEE;
        color: #333333;
        font-size: 14px;
        font-weight: 500;
        .contentHeader{
            display: flex;
            background: #F2F3FA;
            height: 60px;
            line-height: 60px;
            .contentItem:nth-child(1){
                text-align: left;
                padding-left: 26px;
                width: 8%;
            }
            .contentItem:nth-child(2){
                text-align: left;
                width: 32%;
            }
            .contentItem:nth-child(3){
                text-align: center;
                width: 12%;
            }
            .contentItem:nth-child(4){
                text-align: center;
                width: 12%;
            }
            .contentItem:nth-child(5){
                text-align: center;
                width: 12%;
            }
            .contentItem:nth-child(6){
                text-align: center;
                width: 12%;
            }
            .contentItem:nth-child(7){
                text-align: center;
                width: 12%;
            }
        }
        .contentBody{
            display: flex;
            height: 60px;
            line-height: 60px;
            border-bottom: 1px solid #EEEEEE;
            .contentBodyItem:nth-child(1){
                text-align: left;
                padding-left: 26px;
                width: 8%;
                i{
                    cursor: pointer;
                    color: #7D7D7D;
                    font-size: 14px;
                    margin-right: 5px;
                }
            }
            .contentBodyItem:nth-child(2){
                text-align: left;
                width: 32%;
            }
            .contentBodyItem:nth-child(3){
                text-align: center;
                width: 12%;
            }
            .contentBodyItem:nth-child(4){
                text-align: center;
                width: 12%;
            }
            .contentBodyItem:nth-child(5){
                text-align: center;
                width: 12%;
            }
            .contentBodyItem:nth-child(6){
                text-align: center;
                width: 12%;
            }
            .contentBodyItem:nth-child(7){
                text-align: center;
                width: 12%;
            }
        }
        .contentFooter{
            display: flex;
            height: 80px;
            line-height: 80px;
            border-bottom: 1px solid #EEEEEE;
            .contentFooterItem:nth-child(1){
                text-align: left;
                padding-left: 26px;
                width: 8%;
            }
            .contentFooterItem:nth-child(2){
                text-align: left;
                width: 32%;
            }
            .contentFooterItem:nth-child(3){
                text-align: center;
                width: 12%;
            }
            .contentFooterItem:nth-child(4){
                text-align: center;
                width: 12%;
            }
            .contentFooterItem:nth-child(5){
                text-align: center;
                width: 12%;
            }
            .contentFooterItem:nth-child(6){
                text-align: center;
                width: 12%;
            }
            .contentFooterItem:nth-child(7){
                text-align: center;
                width: 12%;
            }
        }
    }
</style>